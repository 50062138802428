export default {
  'result.success.title': 'Submit Success',
  'result.success.description': '', // 提交结果页用于反馈一系列操作任务的处理结果， 如果仅是简单操作，使用 Message 全局提示反馈即可。 本文字区域可以展示简单的补充说明，如果有类似展示 “单据”的需求，下面这个灰色区域可以呈现比较复杂的内容。
  'result.success.operate-title': 'Operation',
  'result.success.operate-id': 'The operation id',
  'result.success.principal': 'Principal',
  'result.success.operate-time': 'Operation time',
  'result.success.step1-title': 'Create project',
  'result.success.step1-operator': '--',
  'result.success.step2-title': 'Departmental preliminary review',
  'result.success.step2-operator': '--',
  'result.success.step2-extra': '--',
  'result.success.step3-title': 'Financial review',
  'result.success.step4-title': 'Finish',
  'result.success.btn-return': 'Return',
  'result.success.btn-project': 'View project',
  'result.success.btn-print': 'Print',
  'result.fail.error.title': 'Submit Failure',
  'result.fail.error.description': 'Please check and try again.',
  'result.fail.error.hint-title': 'Prompt',
  'result.fail.error.hint-text1': 'Account blocked',
  'result.fail.error.hint-btn1': 'Unblock',
  'result.fail.error.hint-text2': 'Your account has not been activated',
  'result.fail.error.hint-btn2': 'Activate immediately',
  'result.fail.error.btn-text': 'Return'
}
