export default {
  'menu.home': 'página delantera',
  'menu.dashboard': 'tablero',
  'menu.dashboard.workplace': 'lugar de trabajo',
  'menu.system': 'sistema',
  'menu.system.list': 'lista de usuarios',
  'menu.system.bypassAccount': 'cuenta de acceso directo',
  'menu.system.logging': 'registro',
  'menu.system.businessDiary': 'diario de negocios',
  'menu.consumer': 'consumidor',
  'menu.consumer.list': 'lista de consumidores',
  'menu.consumer.horizontal': 'consumidor horizontal',
  'menu.consumer.vertical': 'consumidor vertical',
  'menu.order': 'orden',
  'menu.order.list': 'lista de pedidos',
  'menu.order.pay': 'pago',
  'menu.order.details': '	 detalles de la orden',
  'menu.datum': 'datos',
  'menu.datum.list': 'lista de datos',
  'menu.datum.temperature': 'temperatura',
  'menu.datum.healthData': 'datos de salud',
  'menu.datum.datumHorizontal': '	 datos horizontales',
  'menu.datum.datumVertical': 'datos verticales',
  'menu.datum.ecgChart': 'ecgChart',
  'menu.permission': 'permiso',
  'menu.permission.role': 'rol',
  'menu.permission.list': 'lista de permisos',
  'menu.permission.api': 'api',
  'menu.permission.user': 'usuario',
  'menu.permission.dictionary': '	 diccionario',
  'menu.permission.operation': 'operación',
  'menu.permission.group': 'grupo',
  'menu.account': 'cuenta',
  'menu.account.center': 'centro de cuenta',
  'menu.account.settings': 'ajustes de la cuenta',
  'menu.account.settings.basic': 'ajustes básicos',
  'menu.account.settings.changePassword': 'cambiar contraseña',
  'menu.account.logout': 'cerrar sesión'
}
