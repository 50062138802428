import Vue from 'vue'
import App from './App.vue'
import store from './store/'
import router from './router'

import i18n from './locales'
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
