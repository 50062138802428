export default {
  'menu.home': 'Home',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.workplace': 'Workplace',
  'menu.system': 'System',
  'menu.system.list': 'Account',
  'menu.system.bypassAccount': 'Accounts',
  'menu.system.logging': 'Login Log',
  'menu.system.businessDiary': 'Business Log',
  'menu.consumer': 'User',
  'menu.consumer.list': 'Users',
  'menu.consumer.horizontal': 'Horizontal comparison of user data',
  'menu.consumer.vertical': 'Longitudinal comparison of user data',
  'menu.order': 'Order',
  'menu.order.list': 'Orders',
  'menu.order.pay': 'Recharge',
  'menu.order.details': '	Order Details',
  'menu.datum': 'Health Data',
  'menu.datum.list': 'Health Data',
  'menu.datum.temperature': 'Temperature',
  'menu.datum.healthData': 'Healthy Distribution',
  'menu.datum.datumHorizontal': 'Horizontal comparison',
  'menu.datum.datumVertical': 'Vertical comparison',
  'menu.datum.ecgChart': 'Ecg',
  'menu.permission': 'Permission',
  'menu.permission.role': 'Role',
  'menu.permission.list': 'Permissions',
  'menu.permission.api': 'Api',
  'menu.permission.user': 'User',
  'menu.permission.dictionary': 'Dictionary',
  'menu.permission.operation': 'Operation History',
  'menu.permission.group': 'Group',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.settings.basic': 'Basic Info',
  'menu.account.settings.changePassword': 'Change Password',
  'menu.account.logout': 'Logout'
}
