export default {
  'account.settings.menuMap.basic': 'ajustes básicos',
  'account.settings.menuMap.security': 'Configuraciones de seguridad',
  'account.settings.menuMap.custom': 'Configuración personalizada',
  'account.settings.menuMap.binding': 'Enlace de cuenta',
  'account.settings.menuMap.notification': 'Mensaje de notificación',
  'account.settings.basic.avatar': 'Avatar',
  'account.settings.basic.change-avatar': 'Cambiar avatar',
  'account.settings.basic.email': 'Correo electrónico',
  'account.settings.basic.email-message':
    'Por favor, introduzca su correo electrónico!',
  'account.settings.basic.nickname': 'Apodo',
  'account.settings.basic.nickname-message': 'Por favor, introduzca su apodo!',
  'account.settings.basic.profile': 'Perfil personal',
  'account.settings.basic.profile-message':
    'Por favor, introduzca su perfil personal!',
  'account.settings.basic.profile-placeholder':
    'Breve introducción a usted mismo',
  'account.settings.basic.country': 'País/Región',
  'account.settings.basic.country-message': 'Por favor, introduzca su país!',
  'account.settings.basic.geographic': 'Provincia o ciudad',
  'account.settings.basic.geographic-message':
    'Por favor, introduzca su información geográfica!',
  'account.settings.basic.address': 'Dirección',
  'account.settings.basic.address-message':
    'Por favor, introduzca su dirección!',
  'account.settings.basic.phone': 'Número de teléfono',
  'account.settings.basic.phone-message':
    'Por favor, introduzca su número de teléfono!',
  'account.settings.basic.update': 'Actualizar información',
  'account.settings.basic.update.success':
    'Actualización de información exitosa',
  'account.settings.security.strong': 'Fuerte',
  'account.settings.security.medium': 'Medio',
  'account.settings.security.weak': 'Débil',
  'account.settings.security.password': 'Contraseña de la cuenta',
  'account.settings.security.password-description':
    'La fortaleza de la contraseña actual:',
  'account.settings.security.phone': 'Teléfono de seguridad',
  'account.settings.security.phone-description': 'Teléfono vinculado:',
  'account.settings.security.question': 'Pregunta de seguridad',
  'account.settings.security.question-description':
    'La pregunta de seguridad no está configurada, y la protección de la cuenta puede ser más segura después de la configuración',
  'account.settings.security.email': 'Correo electrónico de seguridad',
  'account.settings.security.email-description':
    'Correo electrónico vinculado:',
  'account.settings.security.mfa': 'Dispositivo MFA',
  'account.settings.security.mfa-description':
    'No se ha configurado el dispositivo MFA, después de la configuración, se puede confirmar dos veces',
  'account.settings.security.modify': 'Modificar',
  'account.settings.security.set': 'Configurar',
  'account.settings.security.bind': 'Vincular',
  'account.settings.binding.taobao': 'Vincular Taobao',
  'account.settings.binding.taobao-description':
    'Actualmente no está vinculado a Taobao',
  'account.settings.binding.alipay': 'Vincular Alipay',
  'account.settings.binding.alipay-description':
    'Actualmente no está vinculado a Alipay',
  'account.settings.binding.dingding': 'Vincular DingTalk',
  'account.settings.binding.dingding-description':
    'Actualmente no está vinculado a DingTalk',
  'account.settings.binding.bind': 'Vincular',
  'account.settings.notification.password': 'Cuenta de mensaje',
  'account.settings.notification.password-description':
    'Mensajes de otros usuarios se enviarán a la cuenta de mensaje',
  'account.settings.notification.messages': 'Mensajes del sistema',
  'account.settings.notification.messages-description':
    'Los mensajes del sistema se enviarán a la página de mensajes del sistema',
  'account.settings.notification.todo': 'Notificaciones pendientes',
  'account.settings.notification.todo-description':
    'Las notificaciones pendientes se enviarán a la página de notificaciones pendientes',
  'account.settings.settings.open': 'Abierto',
  'account.settings.settings.close': 'Cerrado',
  'account.center.oldPassword': 'Contraseña anterior',
  'account.center.oldPasswordTip':
    'Por favor, introduzca su contraseña anterior',
  'account.center.password': 'Nueva contraseña',
  'account.center.passwordTip': 'Por favor, introduzca su nueva contraseña',
  'account.center.repeatPassword': 'Confirmar contraseña',
  'account.center.repeatPasswordTip': 'Por favor, confirme su nueva contraseña',
  'account.settings.changePassword': 'Cambiar contraseña'
}
