export default {
  submit: 'Submit',
  save: 'Save',
  'submit.ok': 'Submit Success',
  'save.ok': 'Save Success',
  'table.add': 'Add',
  'table.copy': 'Copy',
  'table.edit': 'Edit',
  'table.delete': 'Delete',
  'table.start_time': 'Start Time',
  'table.end_time': 'End Time',
  'table.date': 'Date',
  'table.inquire': 'Inquire',
  'table.unfold': 'Unfold',
  'table.reset': 'Reset',
  'table.fewer': 'Fewer',
  'table.operation': 'Operation',
  'table.addSuccess': 'Add Success',
  'table.editSuccess': 'Edit Success',
  'table.deleteSuccess': 'Delete Success',
  'table.deletePrompt': 'Are you sure to delete?',
  'table.ok-text': 'OK',
  'table.cancel-text': 'Cancel',
  'table.no-data': 'No Data',
  'table.yes': 'Yes',
  'table.no': 'No',
  'table.batchRemove': 'Batch Remove',
  'table.selected': 'Selected',
  'table.pay': 'Pay',
  'table.viewData': 'View Data',
  'table.detail': 'Detail',
  'table.show': 'Show',
  'table.hidden': 'Hidden',
  'table.all': 'All',
  'table.man': 'Male',
  'table.woman': 'Female',
  'global.welcome': 'Welcome',
  'global.welcomeBack': 'Welcome Back',
  'global.error': 'Error',
  'global.errorMsg': 'Error, please try again later.',
  'global.graphPresentation': 'Graph presentation',
  'table.keyword': 'keyword',
  'global.start_time_placeholder': 'Please enter start time',
  'global.end_time_placeholder': 'Please enter end time',
  'global.select_placeholder': 'Please select',
  'global.log_hint': 'Are you sure to delete the current log?',
  'global.hint': 'Hint',
  'global.date': 'Date',
  'global.back': 'Back',
  'table.change-password': 'Change Password',
  'global.good-morning': 'Good Morning',
  'global.good-noon': 'Good Noon',
  'global.good-afternoon': 'Good Afternoon',
  'global.good-evening': 'Good Evening',
  'show': 'Show',
  'add': 'Add',
  'delete': 'Delete',
  'edit': 'Edit',
  'query': 'Query',
  'get': 'Get',
  'enable': 'Enable',
  'disable': 'Disable',
  'import': 'Import',
  'export': 'Export',
  'set': 'Set',
  'copy': 'Copy',
  'role-level1': 'Level 1',
  'role-level2': 'Level 2',
  'role-level3': 'Level 3',
  'role-level4': 'Level 4',
  'global.download': 'Download',
  'table.total01': 'A total of',
  'table.total02': '',
  'global.closeThat': 'Close That',
  'global.closeRight': 'Close Right',
  'global.closeLeft': 'Close Left',
  'global.closeAll': 'Close All',
  'global.resetZoom': 'Reset Zoom',
  'table.delete-selected': 'Delete Selected Items',
  'table.deleteLogHint': 'Select at least one'
}
