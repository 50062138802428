import Vue from 'vue';

import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    // {
    //   path: '/',
    //   name: 'index',
    //   meta:{
    //     title: ''
    //   },
    //   component: resolve => require(['@/views/index/index'], resolve) 
    // },
    {
      path: '/',
      name: 'reportData',
      meta:{
        title: ''
      },
      component: resolve => require(['@/views/report/index'], resolve) 
    },

  ]
});
