export default {
  'system.list.roleName': 'Role Name',
  'system.list.createTime': 'Create Time',
  'system.list.addRole': 'Add Role',
  'system.list.roleDesc': 'Role description',
  'system.list.creator': 'Creator',
  'system.list.member': 'Member',
  'system.logging.logType': 'Log Type',
  'system.logging.userName': 'User Name',
  'system.logging.name': 'Name',
  'system.logging.ip': 'ip address',
  'system.logging.agent': 'Agent',
  'system.logging.message': 'Message',
  'system.logging.all': 'All',
  'system.logging.login': 'Login',
  'system.logging.visitLogin': 'Visit Login',
  'system.logging.logout': 'Logout',
  'system.logging.uploadTime': 'Upload Time',
  'system.businessDiary.all': 'All',
  'system.businessDiary.logout': 'Logout',
  'system.businessDiary.type01': 'Add User',
  'system.businessDiary.type02': 'User Permissions Settings',
  'system.businessDiary.type03': 'Create Role',
  'system.businessDiary.type04': 'Delete Role',
  'system.businessDiary.type05': 'Copy Role',
  'system.businessDiary.type06': 'Edit Role',
  'system.businessDiary.type07': 'Add Area',
  'system.businessDiary.type08': 'Edit Area',
  'system.businessDiary.type09': 'Delete Area',
  'system.businessDiary.type10': 'Add Group',
  'system.businessDiary.type11': 'Edit Group',
  'system.businessDiary.type12': 'Delete Group',
  'system.businessDiary.type13': 'Add Health Record Manually',
  'system.businessDiary.type14': 'Edit Health Record',
  'system.businessDiary.type15': 'Add Temperature Record Manually',
  'system.businessDiary.type16': 'Edit Temperature Record',
  'system.businessDiary.type17': 'Edit System Settings',
  'system.businessDiary.type18': 'Update User Info'
}
