export default {
  submit: 'enviar',
  save: 'guardar',
  'submit.ok': 'enviar con éxito',
  'save.ok': 'guardar con éxito',
  'table.add': 'añadir',
  'table.copy': 'copiar',
  'table.edit': 'editar',
  'table.delete': 'eliminar',
  'table.start_time': 'hora de inicio',
  'table.end_time': 'hora de finalización',
  'table.date': 'fecha',
  'table.inquire': 'inquirir',
  'table.reset': 'reiniciar',
  'table.unfold': 'desplegar',
  'table.fewer': 'menos',
  'table.operation': 'operación',
  'table.addSuccess': 'añadir con éxito',
  'table.editSuccess': 'editar con éxito',
  'table.deleteSuccess': 'eliminar con éxito',
  'table.deletePrompt': '¿está seguro de que desea eliminar?',
  'table.ok-text': 'ok',
  'table.cancel-text': 'cancelar',
  'table.no-data': 'sin datos',
  'table.yes': 'sí',
  'table.no': 'no',
  'table.batchRemove': 'eliminar en lote',
  'table.selected': 'seleccionado',
  'table.pay': 'pagar',
  'table.viewData': 'ver datos',
  'table.detail': 'detalle',
  'table.show': 'mostrar',
  'table.hidden': 'ocultar',
  'table.all': 'todo',
  'table.man': 'hombre',
  'table.woman': 'mujer',
  'global.welcome': 'bienvenido',
  'global.welcomeBack': 'bienvenido de nuevo',
  'global.error': 'error',
  'global.errorMsg': 'error de mensaje',
  'global.graphPresentation': 'presentación gráfica',
  'table.keyword': 'palabra clave',
  'global.keyword': 'palabra clave',
  'global.start_time_placeholder': 'por favor seleccione la hora de inicio',
  'global.end_time_placeholder': 'por favor seleccione la hora de finalización',
  'global.select_placeholder': 'por favor seleccione',
  'global.log_hint':
    'por favor ingrese el nombre de usuario o el número de teléfono',
  'global.hint': 'por favor ingrese',
  'global.date': 'fecha',
  'global.back': 'atrás',
  'table.change-password': 'cambiar contraseña',
  'global.good-morning': 'buenos días',
  'global.good-noon': 'buenas tardes',
  'global.good-afternoon': 'buenas tardes',
  'global.good-evening': 'buenas noches',
  show: 'mostrar',
  add: 'añadir',
  delete: 'eliminar',
  edit: 'editar',
  query: 'inquirir',
  get: 'obtener',
  enable: 'habilitar',
  disable: 'deshabilitar',
  import: 'importar',
  export: 'exportar',
  set: 'configurar',
  copy: 'copiar',
  'role-level1': 'nivel 1',
  'role-level2': 'nivel 2',
  'role-level3': 'nivel 3',
  'role-level4': 'nivel 4',
  'global.download': 'descargar',
  'table.total01': 'total',
  'table.total012': 'registros',
  'global.closeThat': 'cierre la pestaña actual',
  'global.closeRight': 'cierre la pestaña de la derecha',
  'global.closeLeft': 'cierre la pestaña de la izquierda',
  'global.closeAll': 'cierre todas las pestañas',
  'global.resetZoom': 'reiniciar zoom',
  'table.delete-selected': 'eliminar seleccionado',
  'table.deleteLogHint': 'Seleccione al menos uno'
}
