export default {
  'datum.list.id': 'ID',
  'datum.list.account': 'cuenta',
  'datum.list.resourceType': 'tipo de recurso',
  'datum.list.uploadTime': 'tiempo de carga',
  'datum.list.systolic': 'sistólico',
  'datum.list.diastolic': 'diastólico',
  'datum.list.spo2': 'spo2',
  'datum.list.heartRate': 'frecuencia cardíaca',
  'datum.list.fatigue': 'fatiga',
  'datum.list.pressure': 'presión',
  'datum.list.respiratoryRate': 'frecuencia respiratoria',
  'datum.list.RespiratoryVariability': 'variabilidad respiratoria',
  'datum.list.heartRateVariability': 'variabilidad de la frecuencia cardíaca',
  'datum.list.usernameMessage': 'por favor ingrese el nombre de usuario',
  'datum.list.systolicMessage': 'por favor ingrese el valor sistólico',
  'datum.list.diastolicMessage': 'por favor ingrese el valor diastólico',
  'datum.list.spo2Message': 'por favor ingrese el valor spo2',
  'datum.list.heartRateMessage':
    'por favor ingrese el valor de la frecuencia cardíaca',
  'datum.list.fatigueMessage': 'por favor ingrese el valor de la fatiga',
  'datum.list.pressureMessage': 'por favor ingrese el valor de la presión',
  'datum.list.respiratoryRateMessage':
    'por favor ingrese el valor de la frecuencia respiratoria',
  'datum.list.heartRateVariabilityMessage':
    'por favor ingrese el valor de la variabilidad de la frecuencia cardíaca',
  'datum.list.keyword': 'palabra clave',
  'datum.list.keywordPlaceholder': 'por favor ingrese la palabra clave',
  'datum.list.orderKey': 'clave de orden',
  'datum.list.add': 'añadir',
  'datum.list.edit': 'editar',
  'datum.list.temperature': 'temperatura',
  'datum.temperature.keyword': 'palabra clave',
  'datum.temperature.orderKey': 'clave de orden',
  'datum.temperature.isSort': 'ordenar',
  'datum.temperature.username': 'nombre de usuario',
  'datum.temperature.uploadTime': 'tiempo de carga',
  'datum.temperature.temperature': 'temperatura',
  'datum.temperature.systolic': 'sistólico',
  'datum.temperature.diastolic': 'diastólico',
  'datum.temperature.heart_rate': 'frecuencia cardíaca',
  'datum.temperature.spo2': 'spo2',
  'datum.temperature.fatigue': 'fatiga',
  'datum.temperature.pressure': 'presión',
  'datum.temperature.respirator_rate': 'frecuencia respiratoria',
  'datum.healthData.sex': 'sexo',
  'datum.healthData.age': 'edad',
  'datum.healthData.healthIndex': 'índice de salud',
  'datum.healthData.bloodPressure': 'presión arterial',
  'datum.healthData.spo2': 'spo2',
  'datum.healthData.heartRate': 'frecuencia cardíaca',
  'datum.healthData.fatigue': 'fatiga',
  'datum.healthData.pressure': 'presión',
  'datum.healthData.temperature': 'temperatura',
  'datum.healthData.heartRateVariability':
    'variabilidad de la frecuencia cardíaca',
  'datum.datumHorizontal.avgData': 'datos promedio',
  'datum.datumHorizontal.personData': 'datos personales',
  'datum.healthGuidance': 'orientación de salud',
  'datum.healthGuidance.text':
    '根据你的健康水平,坚持每天运动半小时，保持良好的饮食习惯,系统已发送通知给家庭医生',
  'datum.toggleBar': 'barra de alternancia',
  'datum.toggleLine': 'línea de alternancia',
  'datum.reportCard': 'Informe de Monitoreo de Salud',
  'datum.downloadReport': 'descargar informe',
  'datum.createReport': 'crear informe',
  'datum.list.title': 'título',
  'datum.list.result': 'resultado',
  'datum.list.unit': 'unidad',
  'datum.list.reference': 'referencia',
  'datum.list.samplingTime': 'tiempo de muestreo',
  'datum.list.reportTime': 'tiempo de informe',
  'datum.list.createTime': 'tiempo de creación',
  'datum.list.cardHint': 'por favor ingrese el título del informe',
  'datum.temperature.unit': '℃',
  'datum.list.name': 'nombre',
  'datum.list.sex': 'sexo',
  'datum.list.age': 'edad',
  'datum.temperature.temperatureStatus': 'estado de temperatura',
  'datum.temperature.temperatureStatus01': 'normal',
  'datum.temperature.temperatureStatus02': 'subfebril',
  'datum.temperature.temperatureStatus03': 'febril',
  'datum.temperature.temperatureStatus04': 'hipotermia',
  'datum.temperature.temperatureStatus05': 'hipertermia',
  'datum.checkECG': 'ver ECG',
  'datum.ecgChart.ecg': 'ECG',
  'datum.ecgChart.ppg': 'PPG',
  'datum.switchChart': 'cambiar gráfico',
  'datum.normal-blood-pressure': 'presión arterial normal',
  'datum.low-blood-pressure': 'presión arterial baja',
  'datum.high-blood-pressure': 'presión arterial alta',
  'datum.normal-spo2': 'spo2 normal',
  'datum.more-low-spo2': 'spo2 más bajo',
  'datum.low-spo2': 'spo2 bajo',
  'datum.too-fast-heartRate': 'frecuencia cardíaca demasiado rápida',
  'datum.normal-heartRate': 'frecuencia cardíaca normal',
  'datum.low-heartRate': 'frecuencia cardíaca baja',
  'datum.high-temperature': 'temperatura alta',
  'datum.normal-temperature': 'temperatura normal',
  'datum.low-temperature': 'temperatura baja',
  'datum.list.ecgHint': 'por favor ingrese el valor de ECG'
}
